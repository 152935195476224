#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}

.App {
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}
